import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { Box } from "@chakra-ui/core";

const Logo = ({ data }) => {
  const imageData = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "images/logo-combined.png" }) {
        childImageSharp {
          fixed(height: 150) {
            base64
            aspectRatio
            width
            height
            src
            srcSet
            srcWebp
            srcSetWebp
            originalName
          }
        }
      }
    }
  `);
  return (
    <Box className="logo" position={["static"]} left="0" right="0" m={["0"]}>
      <Img
        fixed={imageData.file.childImageSharp.fixed}
        alt="Site Logo"
        className="site-logo"
        itemProp="logo"
        style={{
          display: "block",
          width: "70px",
          height: "35px",
        }}
      />
    </Box>
  );
};

export default Logo;
