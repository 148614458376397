import React from "react";
import { Box } from "@chakra-ui/core";
import Menu from "./Menu";
import LangSwitcher from "./LangSwitcher";

const Header = ({ maxW = "4xl" }) => (
  <Box className="header" bg="headerBg" p=".5rem 2rem" minH="50px">
    <Box maxW={maxW} m="auto">
      <Box
        display={["block", "flex"]}
        alignItems="center"
        justify={["inherit", "space-between"]}
        className="nav-wrapper"
      >
        <Menu />
        <Box display={["none", "none", "block"]}>
          <LangSwitcher />
        </Box>
      </Box>
    </Box>
  </Box>
);

export default Header;
