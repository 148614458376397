import React from "react";
import { Box, Text } from "@chakra-ui/core";
import { navigate } from "gatsby";

function getCurrentURL() {
  return window.location.pathname;
}

export default () => {
  return (
    <Box as="span" color="navLink" py={[2, 2, "0"]} display="block">
      <Box
        as="button"
        display="block"
        ml="auto"
        mt={[4, 4, 0]}
        onClick={() => {
          const location = getCurrentURL();
          if (location.indexOf("/en") === 0) {
            /* It's English, we want to go Dutch */
            switch (location) {
              case "/en" || "/en/":
                navigate("/");
                break;
              case "/en/about" || "/en/about/":
                navigate("/about");
                break;
            }
          }
        }}
      >
        <Text fontSize={["md", "md", "sm"]} m={0}>
          NL
        </Text>
      </Box>

      <Box
        as="button"
        display="block"
        ml="auto"
        mt={[4, 4, 0]}
        onClick={() => {
          const location = getCurrentURL();
          if (location.indexOf("/en") !== 0) {
            /* it's Dutch, we want to go English */
            switch (location) {
              case "/" || "":
                navigate("/en");
                break;
              case "/about" || "/about/":
                navigate("/en/about");
                break;
            }
          }
        }}
      >
        <Text fontSize={["md", "md", "sm"]} m={0}>
          EN
        </Text>
      </Box>
    </Box>
  );
};
