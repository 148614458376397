import React from "react";
import { Box, Text, Image } from "@chakra-ui/core";
import { Link } from "gatsby";
import useSiteMetadata from "gatsby-plugin-based-on-staticfuse/src/hooks/use-site-metadata";
import LogoCreate from "../../images/create_logo_liggend_small.jpg";
import LogoUvA from "../../images/uva-logo-white.png";

const Footer = () => {
  const { author } = useSiteMetadata();

  return (
    <Box as="footer" bg="footerBg" p={4} w="100%" className="site-footer">
      <Box
        display="flex"
        maxW="4xl"
        m="auto"
        overflow="hidden"
        color="muted"
        fontSize="sm"
        justifyContent="space-between"
      >
        <Box
          w="100%"
          fontWeight="500"
          fontSize="base"
          color="footerText"
          textAlign="center"
        >
          <Link style={{ whiteSpace: "nowrap" }} to="/privacy-policy">
            Privacy Policy
          </Link>
        </Box>
      </Box>
      <Box
        d="flex"
        borderTop="1px solid rgba(255,255,255,.2)"
        py={4}
        maxW="4xl"
        m="auto"
        mt={2}
        textAlign="center"
        justifyContent="space-between"
        flexDirection={["column", "column", "row"]}
      >
        <Image
          h="40px"
          objectFit="contain"
          src={LogoUvA}
          alt="Universiteit van Amsterdam"
          mb={4}
        />
        <Image
          h="40px"
          objectFit="contain"
          src={LogoCreate}
          alt="Create Lab"
          mb={4}
        />

        <Text color="footerText" fontSize="sm" opacity=".7" m={0}>
          © {new Date().getFullYear()} {author}
        </Text>
      </Box>
    </Box>
  );
};

export default Footer;
