import React, { useState } from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import { Box, Text, Flex } from "@chakra-ui/core";
import useSiteMetadata from "gatsby-plugin-based-on-staticfuse/src/hooks/use-site-metadata";
import usePublisherOptions from "gatsby-plugin-based-on-staticfuse/src/hooks/use-publisher-options";
import Logo from "./Logo";
import SearchBar from "gatsby-plugin-based-on-staticfuse/src/components/SearchBar";
import HamburgerMenu from "gatsby-plugin-based-on-staticfuse/src/components/HamburgerMenu";
import MenuItem from "gatsby-plugin-based-on-staticfuse/src/components/MenuItem";
import LangSwitcher from "./LangSwitcher";

/**
 * Get all menues with children.
 * We'll use this query to try and pull out the menu from the theme settings.
 */
const MENU_QUERY = graphql`
  fragment MenuFields on WPGraphQL_MenuItem {
    id
    label
    url
    connectedObject {
      __typename
    }
  }

  query GET_MENU_ITEMS_2 {
    wpgraphql {
      menus {
        edges {
          node {
            id
            menuId
            name
            slug
            count
            menuItems {
              nodes {
                ...MenuFields
                childItems {
                  nodes {
                    ...MenuFields
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Menu = () => {
  const { title } = useSiteMetadata();

  const { menuName, publisherMenuConfig } = usePublisherOptions();
  const [menuOpened, openMenu] = useState(false);

  return (
    <StaticQuery
      query={MENU_QUERY}
      render={() => {
        return (
          <Box
            className="menu-wrapper"
            position={["absolute", "absolute", "static"]}
            display={["block", "block", "flex"]}
            top="0"
            left="0"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            zIndex="99"
            overflow={["hidden", "hidden", "visible"]}
            transition={
              menuOpened
                ? "all 0.3s ease-in, background 0.5s ease-in"
                : "all 0.5s ease-out, background 1s ease-out"
            }
            style={{
              transitionDelay: ".1s",
            }}
            height={menuOpened ? "100%" : "50px"}
            bg="headerBg"
            p={3}
          >
            <Link className="home-link" to="/" rel="home" itemProp="url">
              <Flex align="center" justify={["left", "center"]}>
                <Logo />
                <Text
                  fontSize={["sm", "sm", "md"]}
                  fontWeight="bold"
                  m={0}
                  ml={3}
                >
                  {title}
                </Text>
              </Flex>
            </Link>

            <div
              onClick={() => (menuOpened ? openMenu(false) : openMenu(true))}
              onKeyDown={() => (menuOpened ? openMenu(false) : openMenu(true))}
              role="button"
              tabIndex={0}
              style={{ position: "absolute", right: 0, top: 0 }}
            >
              <HamburgerMenu menuOpen={menuOpened} />
            </div>

            <Box display={["block", "block", "flex"]} alignItems="center">
              <Box display={["block", "block", "none"]}>
                <LangSwitcher />
              </Box>
              <Box order="2">
                <SearchBar menuOpen={menuOpened} />
              </Box>

              <Box
                as="ul"
                transition="transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99), -webkit-transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99)"
                transform={[
                  menuOpened
                    ? "scale(1) translateY(0px)"
                    : "scale(.95) translateY(-10px)",
                  menuOpened
                    ? "scale(1) translateY(0px)"
                    : "scale(.95) translateY(-10px)",
                  "scale(1) translateY(0px)",
                ]}
                zIndex="1"
                style={{
                  transitionDelay: ".1s",
                }}
                className="menu-list"
                listStyleType="none"
                display={["block", "block", "flex"]}
                align="center"
                position="relative"
                m="0"
                p={[4, 4, "0"]}
              >
                {publisherMenuConfig.nodes.map((menuItem) => (
                  <MenuItem key={menuItem.label} menuItem={menuItem} />
                ))}
              </Box>
            </Box>
          </Box>
        );
      }}
    />
  );
};

export default Menu;
