import React from "react";
import { Box, Flex } from "@chakra-ui/core";
import PropTypes from "prop-types";
import Header from "./Header";
import Footer from "./Footer";
import Globals from "gatsby-plugin-based-on-staticfuse/src/components/Globals";

const Layout = (props) => (
  <Flex direction="column" minH="100vh">
    <Globals />
    <Header />
    <Box
      className="site-content"
      width={props.flexBody ? "100%" : "auto"}
      maxW={props.fullWidth ? "100%" : "5xl"}
      m={["1.5rem auto"]}
      p={[4, 4, 4, 0]}
      flex="1"
      display={props.flexBody ? "flex" : "block"}
      position="relative"
      flexDir={props.flexBody ? "row" : undefined}
    >
      {props.children}
    </Box>
    <Footer />
  </Flex>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
